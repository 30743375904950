.result-wrapper{
    width: 100%;
    height: 100vh;
    background: rgb(18,194,233);
    background: linear-gradient(180deg, rgba(18,194,233,1) 0%, rgba(196,113,237,1) 50%, rgba(255,10,69,1) 100%);
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.card-wrapper{
    width: 60%;
    height: 50%;
    background-color: white;
    box-sizing: border-box;
    padding: 20px;
    border-radius:3px;
    -webkit-animation: tilt-in-fwd-tr 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: tilt-in-fwd-tr 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.content-wrapper{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

element.style {
}
.p-button-label {
    flex: 1 1 auto;
}
.p-component, .p-component * {
    box-sizing: border-box;
}
.p-button-label {
    font-weight: 700;
}
* {
    box-sizing: border-box;
}
.bottom-info{
    color:white;
    height:100%;
    display: flex;
    align-items:center;
    justify-content: center;
    margin-right: 10px;
    gap:20px;
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
}
.overlay-top{
    width: 100%;
    height: 180px;
    background: linear-gradient(180deg, rgba(0,0,0,0.9) 0%, rgba(255,255,255,0) 100%);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 20px;
}
.overlay-bottom{
    width: 100%;
    height: 120px;
    background: black;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}
.bottom-logo-img{
    height: 80%;
    margin-left: 10px;
    margin-top:5px;
}
.send-button{
    width: 40%;
    height:70px;
    background-color: white;
    color:#C471ED;
    font-size: 30px;
    text-transform:uppercase;
}
.send-button:hover{
    background-color: white!important;
    color:#C471ED!important;
    border:1px solid white !important;
}
.picture-button{
    width: 40%;
    height:70px;
    background-color: #C471ED;
    color:white;
    font-size: 30px;
    text-transform:uppercase;
}
.picture-button:hover{
    background-color: #C471ED!important;
    color:white!important;
    border:1px solid #C471ED!important;
}
.result-button-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 100px;
    gap:40px
}
@-webkit-keyframes tilt-in-fwd-tr {
    0% {
        -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
        transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
    }
}
@keyframes tilt-in-fwd-tr {
    0% {
        -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
        transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
    }
}
.spinner-wrapper{
    position: absolute;
    display: block;
    right: 120px;
    top: 30px;
}
