.home-wrapper{
    width: 100%;
    height: 100vh;
    background: rgb(18,194,233);
    background: linear-gradient(180deg, rgba(18,194,233,1) 0%, rgba(196,113,237,1) 50%, rgba(255,10,69,1) 100%);
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
}

.languages-wrapper{
    display:flex;
    position: absolute;
    bottom: 0;
    margin-bottom: 30px;
    z-index:2;
    justify-content: end;
    gap:20px
}
.try-span{
    display:flex;
    position: absolute;
    bottom: 0;
    margin-bottom: 200px;
    font-size: 35px;
    color:white;
    z-index:2;
    justify-content: end;
    font-family:Arial
}
.img-wrapper{
    background-color: blue;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    border:3px solid white;
}
.flag-wrapper{
    display: flex;
    flex-direction: column;
    animation:flag-animation 0.5s;
}
.flag-title{
    margin-top: 10px;
    font-size: 25px;
    color: white;
}


@keyframes flag-animation {
    0%{
      opacity: 0;
    }
    100%{
        opacity: 1;
    }

}