.login-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding: 100px;
}
.span-wrapper{
    display: flex;
    font-size: 30px;
    padding: 70px 50px;
}
.input-wrapper{
    display: flex;
    flex-direction: column;
    align-items:center;
    width: 100%;
    justify-content: center;
    gap:50px
}
.login-input-header{
    width: 80%;
}
.login-input{
    width: 80%;
    height:70px;
    color: black;
    font-size: 25px !important;
}
.image-wrapper{
    display: flex;
    margin-top:50px;
    gap:50px
}
.checkbox-wrapper{
    display: flex;
    margin-top: 50px;
}
.buttons-wrapper{
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    width: 90%;
}
.login-buttons{
    width: 120px!important;
    height:70px!important;
    background-color:#C471ED!important;
    border-color: #C471ED !important;

}
.p-checkbox .p-checkbox-box.p-highlight{
    background-color: #C471ED !important;
    border-color: #C471ED !important;
    height: 30px!important;

}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{
    border-color: #C471ED !important;

}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #C471ED;
}
.login-label{
    font-size: 27px;
    color:#C471ED;
    font-weight: bold;
    margin-left: 10px;

}
.login-checkbox{
    margin-top: 5px;
}
.button-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}
.confirm-button{
    display:flex;
    width: 80% !important;
    background-color: #C471ED !important;
}
.custom-container-class{
    width: 80% !important;
    display:flex;

}
.custom-input-class{
    width:100% !important;
    height:70px!important;
    margin-left:40px!important;
    font-size:25px!important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"!important;
    color:#495057!important;
}
.flag{
    zoom:1.3;
}
.country{
    height: 60px!important;
    font-size:20px!important;
}
.custom-dropdown-class{
    width:704px!important;
    margin-left: 634px!important;
    margin-top:70px!important;
    max-height: 450px!important;
}
.custom-button-class{
    width: 70px!important;
    background-color: white !important;
    display: flex;
    justify-content: center;
}
.phone-wrapper{
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
}
.input-error{
    border-radius: 15px;
    border: 1px solid red!important;
}
